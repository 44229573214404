import { useMemo } from 'react';
import { getCurrentYear } from '@helpers/date';
import { calculateUserAgreementUrl } from '@helpers/misc';
import { getOriginDomain, getSupportEmail } from '@helpers/url';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { useTypedSelector } from '@hooks/store';
import { TERM_FOR_EU_RESIDENT } from '@scenes/terms-for-eu-residents';
import { FooterAddress } from '@kit/footer/footer-address';
import { FooterExtraLinks } from '@kit/footer/footer-extra';
import { ContentDesktop } from './content/desktop';
import { ContentMobile } from './content/mobile';
import { ContentTablet } from './content/tablet';
import { footerWidgetAddressClassName, FooterWidgetStyled } from './styled';
import { FooterWidgetContentProps, FooterWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FooterWidget: AppFC<FooterWidgetProps> = props => {
  const {
    logoImageSrc
  } = props;
  const domain = getOriginDomain();
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const supportEmail = useMemo(() => getSupportEmail(), []);
  const footerContentProps: FooterWidgetContentProps = useMemo(() => {
    return {
      indexRouteDescriptor: ROUTES.index.getDescriptor(),
      linksProps: [{
        href: calculateUserAgreementUrl(roleType),
        children: 'Пользовательское соглашение'
      }, {
        href: ROUTES.privacyPolicy.index.getUrl(),
        children: 'Политика конфиденциальности'
      }, {
        href: ROUTES.tariffs.index.getUrl(),
        children: 'Тарифы'
      }],
      emailLinkProps: {
        href: `mailto:${supportEmail}`,
        children: `${supportEmail}`
      },
      contactLinkProps: {
        href: ROUTES.contacts.index.getUrl(),
        children: `Контакты`
      },
      termsForEuResidentsLinkProps: {
        href: ROUTES.termsForEuResidents.index.getUrl(),
        children: 'T&C for EU Residents'
      },
      rightOfWithdrawalLinkProps: {
        href: ROUTES.rightOfWithdrawalForEuResidents.index.getUrl(),
        children: 'Right of Withdrawal for EU Residents'
      },
      notificationOfIllegalityLinkProps: {
        href: `${ROUTES.termsForEuResidents.index.getUrl()}#${TERM_FOR_EU_RESIDENT.NOTIFICATION_OF_ILLEGALITY}`,
        children: 'Notification of illegality'
      },
      copyright: `${getCurrentYear()}`,
      domain,
      logoImageSrc
    };
  }, [domain, logoImageSrc, roleType, supportEmail]);
  return _jsxs(FooterWidgetStyled, {
    children: [_jsx(ContentMobile, {
      ...footerContentProps
    }), _jsx(ContentTablet, {
      ...footerContentProps
    }), _jsx(ContentDesktop, {
      ...footerContentProps
    }), _jsx(FooterAddress, {
      className: footerWidgetAddressClassName
    }), _jsx(FooterExtraLinks, {
      termsForEuResidentsLinkProps: footerContentProps.termsForEuResidentsLinkProps,
      rightOfWithdrawalLinkProps: footerContentProps.rightOfWithdrawalLinkProps,
      notificationOfIllegalityLinkProps: footerContentProps.notificationOfIllegalityLinkProps
    })]
  });
};