import { AppFCC } from '@types';
import { FooterWidget } from '@widgets/landing/landing-client/footer';
import { HeaderWidget } from '@widgets/landing/landing-client/header';
import { LandingMainLayoutWidgetContentStyled, LayoutWidgetContainerStyled, LayoutWidgetFooterStyled, LayoutWidgetHeaderStyled, LayoutWidgetStyled } from './styled';
import { LayoutWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const LayoutWidget: AppFCC<LayoutWidgetProps> = props => {
  const {
    children,
    containerClassName,
    headerLogoImageSrc,
    footerLogoImageSrc,
    headerButtonClassName
  } = props;
  return _jsxs(LayoutWidgetStyled, {
    children: [_jsx(LayoutWidgetHeaderStyled, {
      children: _jsx(LayoutWidgetContainerStyled, {
        children: _jsx(HeaderWidget, {
          logoImageSrc: headerLogoImageSrc //
          ,
          buttonClassName: headerButtonClassName
        })
      })
    }), _jsx(LandingMainLayoutWidgetContentStyled, {
      children: _jsx(LayoutWidgetContainerStyled, {
        className: containerClassName,
        children: children
      })
    }), _jsx(LayoutWidgetFooterStyled, {
      children: _jsx(LayoutWidgetContainerStyled, {
        children: _jsx(FooterWidget, {
          logoImageSrc: footerLogoImageSrc
        })
      })
    })]
  });
};