import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { NAVIGATION_MODE, QUERY_PARAM } from '@config/routing';
import { getOriginDomain } from '@helpers/url';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { useNavigator } from '@hooks/routing';
import { HeaderWidgetProps } from '@widgets/landing/landing-client/header/types';
import { BUTTON_BASE_COLORED_COLOR } from '@kit/buttons/button-base-colored';
import { HeaderWidgetLoginButtonStyled, HeaderWidgetLogoLinkStyled, HeaderWidgetLogoStyled, HeaderWidgetStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const HeaderWidget: AppFC<HeaderWidgetProps> = props => {
  const {
    logoImageSrc,
    buttonClassName
  } = props;
  const navigator = useNavigator();
  const click = useCallback(() => {
    navigator.navigateQuery({
      [QUERY_PARAM.SOURCE]: POPUP.ACCOUNT_LOGIN_MODAL
    }, {
      mode: NAVIGATION_MODE.REPLACE
    });
  }, [navigator]);
  return _jsxs(HeaderWidgetStyled, {
    children: [_jsx(HeaderWidgetLogoLinkStyled, {
      routeDescriptor: ROUTES.index.getDescriptor(),
      children: _jsx(HeaderWidgetLogoStyled //
      , {
        src: logoImageSrc,
        alt: getOriginDomain(),
        loaderDisabled: true
      })
    }), _jsx(HeaderWidgetLoginButtonStyled, {
      className: buttonClassName,
      color: BUTTON_BASE_COLORED_COLOR.BLUE_BORDERS,
      onClick: click,
      children: "\u0412\u043E\u0439\u0442\u0438"
    })]
  });
};